@import "_globals";

.seminarteaser {
    background: @c-lightblue-500;
    padding: 26px;
    margin-bottom: 26px;


    .seminarteaser-headline {
        background: #7fabd0;
        margin: -25px -25px 0;
        padding: 16px 26px 18px;

        h2 {
            color: #ffffff;
            margin: 0;
            font-size: 16px;
        }
    }

    > .seminarteaser-element {
        margin-top: 25px;
    }

    > .seminarteaser-element ~ .seminarteaser-element {
        margin-top: unset;
    }

    .seminarteaser-element .seminarteaser-element-header {
        h3 {
            margin-bottom: 0;
        }
        a {
            font-size: 16px;
        }
    }

    .seminarteaser-text {
        padding-top: 20px;
    }

    .seminarteaser-solrurl {
        margin-bottom: 0;
    }
}