.seminarteaser {
  background: #ecf5fd;
  padding: 26px;
  margin-bottom: 26px;
}
.seminarteaser .seminarteaser-headline {
  background: #7fabd0;
  margin: -25px -25px 0;
  padding: 16px 26px 18px;
}
.seminarteaser .seminarteaser-headline h2 {
  color: #ffffff;
  margin: 0;
  font-size: 16px;
}
.seminarteaser > .seminarteaser-element {
  margin-top: 25px;
}
.seminarteaser > .seminarteaser-element ~ .seminarteaser-element {
  margin-top: unset;
}
.seminarteaser .seminarteaser-element .seminarteaser-element-header h3 {
  margin-bottom: 0;
}
.seminarteaser .seminarteaser-element .seminarteaser-element-header a {
  font-size: 16px;
}
.seminarteaser .seminarteaser-text {
  padding-top: 20px;
}
.seminarteaser .seminarteaser-solrurl {
  margin-bottom: 0;
}
